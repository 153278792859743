<template>
  <div
    v-if="false"
    class="body-login"
  >
    <div class="content-overlay" />
    <div class="content-wrapper">
      <div class="content-header row" />
      <div class="content-body">
        <div class="auth-wrapper auth-v2">
          <b-row class="auth-inner m-0">

            <!-- Brand logo-->
            <a
              class="brand-logo"
              href="https://www.icrea.cat"
            />

            <!-- /Brand logo-->
            <!-- Left Text-->
            <div
              class="d-none d-lg-flex col-lg-8 align-items-center p-5"
              :style="{'background': 'url(' + require('@/assets/images/illustration/bg-login.svg') + ') center center no-repeat', 'background-size': 'cover'}"
            >
              <div
                class="
                  w-100
                  d-lg-flex
                  align-items-center
                  justify-content-center
                  flex-column
                  px-5
                "
              >
                <div class="mb-4">
                  <img
                    class="img-fluid"
                    :src="require('@/assets/images/logo/logo-icrea-white-login.svg')"
                    alt="ICREA"
                  >
                </div>
                <!-- <div class="quote">
                  <h1>
                    <em>"You never fail until you stop trying"</em>
                  </h1>
                  <hr>
                  <p>Albert Einstein</p>
                </div> -->
              </div>
            </div>
            <!-- /Left Text-->

            <!-- Login-->
            <b-col
              lg="4"
              class="d-flex align-items-center auth-bg px-2 p-lg-5"
            >
              <b-col
                sm="8"
                md="6"
                lg="12"
                class="px-xl-2 mx-auto"
              >
                <h2 class="card-title fw-bold mb-1">
                  YOU ARE IN APPLICANTS AREA
                </h2>
                <div class="alert alert-warning">
                  <div class="alert-body text-dark">
                    To go to the <strong>general intranet</strong> for researchers / evaluators / universities <a
                      @click="$router.push({ name: 'admin.login' })"
                    ><u><strong>please click here</strong>.</u></a>
                  </div>
                </div>
                <p class="card-text mb-2">
                  If you want to have acces as an applicant, please introduce your credentials:
                </p>
                <b-card-text class="mb-2 alert-login">
                  <span>The system is running on a new platform, and old accounts are no longer valid. All applicants are kindly requested to create new accounts for the 2024 ICREA call.</span>
                </b-card-text>

                <!-- form -->
                <validation-observer ref="loginValidation">
                  <b-form
                    class="auth-login-form mt-2"
                    @submit.prevent
                  >
                    <!-- email -->
                    <b-form-group
                      label="Email"
                      label-for="login-email"
                    >
                      <validation-provider
                        #default="{ errors }"
                        name="Email"
                        rules="required|email"
                      >
                        <b-form-input
                          id="login-email"
                          v-model="userEmail"
                          :state="errors.length > 0 ? false:null"
                          name="login-email"
                          placeholder="john@example.com"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- forgot password -->
                    <b-form-group>
                      <div class="d-flex justify-content-between">
                        <label for="login-password">Password</label>
                        <b-link :to="{name:'reset-password'}">
                          <small>Forgot Password?</small>
                        </b-link>
                      </div>
                      <validation-provider
                        #default="{ errors }"
                        name="Password"
                        rules="required"
                      >
                        <b-input-group
                          class="input-group-merge"
                          :class="errors.length > 0 ? 'is-invalid':null"
                        >
                          <b-form-input
                            id="login-password"
                            v-model="password"
                            :state="errors.length > 0 ? false:null"
                            class="form-control-merge"
                            :type="passwordFieldType"
                            name="login-password"
                            placeholder="············"
                          />
                          <b-input-group-append is-text>
                            <feather-icon
                              class="cursor-pointer"
                              :icon="passwordToggleIcon"
                              @click="togglePasswordVisibility"
                            />
                          </b-input-group-append>
                        </b-input-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>

                    <!-- checkbox -->
                    <b-form-group
                      class="remember-control"
                    >
                      <b-form-checkbox
                        id="remember-me"
                        v-model="status"
                        name="checkbox-1"
                      >
                        Remember Me
                      </b-form-checkbox>
                    </b-form-group>

                    <!-- submit buttons -->
                    <b-button
                      v-if="!loading"
                      type="submit"
                      variant="primary"
                      block
                      @click="validationForm"
                    >
                      Sign in
                    </b-button>

                    <b-button
                      v-else
                      type="submit"
                      variant="primary"
                      block
                      disabled
                    >
                      <div class="loading">
                        <div class="effect-1 effects" />
                        <div class="effect-2 effects" />
                        <div class="effect-3 effects" />
                      </div>
                    </b-button>
                  </b-form>
                </validation-observer>

                <b-card-text class="text-center mt-2">
                  <span>If you don't have an account, </span>
                  <b-link :to="{name:'front.register'}">
                    <span>&nbsp;Sign up</span>
                  </b-link>
                </b-card-text>
              </b-col>
            </b-col>
            <!-- /Login-->
          </b-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import VuexyLogo from '@core/layouts/components/Logo.vue'
import {
  BRow, BCol, BLink, BFormGroup, BFormInput, BInputGroupAppend, BInputGroup, BFormCheckbox, BCardText, BCardTitle, BImg, BForm, BButton,
} from 'bootstrap-vue'
import { required, email } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import store from '@/store/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { mapGetters } from 'vuex'

require('@core/scss/core.scss')
require('@/assets/scss/style.scss')

export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    VuexyLogo,
    ValidationProvider,
    ValidationObserver,
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: '',
      password: '',
      userEmail: '',
      sideImg: require('@/assets/images/pages/login-v2.svg'),
      // validation rulesimport store from '@/store/index'
      required,
      email,
      loading: false,
      buttonTxt: 'Sign in',
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    passwordToggleIcon() {
      return this.passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === 'dark') {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require('@/assets/images/pages/login-v2-dark.svg')
        return this.sideImg
      }
      return this.sideImg
    },
  },
  mounted() {
    this.$router.push({ name: 'admin.login' })
  },
  methods: {
    loadingMode(status = true) {
      this.loading = status
    },
    async validationForm() {
      this.loadingMode()

      this.$refs.loginValidation.validate()
        .then(async success => {
          if (success) {
            await this.$store.dispatch('auth/login', {
              email: this.userEmail,
              password: this.password,
            }).then(() => {
              this.$router.push({ name: 'front.senior-call' })
            })
          } else {
            this.loadingMode(false)
          }
          this.loadingMode(false)
          if (!this.user.id) {
            this.$toast.error('Credentials incorrect')
          }
        }).catch(exception => {
          this.loadingMode(false)
          this.$toast({
            component: ToastificationContent,
            props: {
              title: exception,
              icon: 'ErrorIcon',
              variant: 'error',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/page-auth.scss';
</style>
